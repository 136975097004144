
/* eslint-disable vue/no-unused-components */
import { defineComponent } from "vue";
import {scrollTo} from '@/utils'
import scroll from '@/scroll'
import {useRouter} from 'vue-router'
import {loginState} from '@/loginState'
import {currUserAsset} from './userAsset'
import Isotope from '@/components/ux/IsotopeTwo.vue'
import store from '@/store'

export default defineComponent({
    props: {
        headImage: {
        default: "",
        },
        currTab:{default: ""}
    },
    components:{
        Isotope
    },
    setup(props){
        const router = useRouter()
        const onClickAsset = (asset:any) => {
            currUserAsset.value = {name: asset.name, urls: asset.url}
            router.push('/user-asset')
        }

        return {
            router:useRouter(),
            scroll,
            scrollTo,
            loginState,
            onClickAsset,
            store
        }
    }
});
